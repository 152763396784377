"use client";

// Next
import { useTranslations } from "next-intl";

// Utils
import { getFQCN } from "@phpcreation/frontend-utils-react-nextjs-bundle/utils/functions";
import { CardListing } from "@phpcreation/frontend-components-react-nextjs-bundle/components";

const arrayNav = [
  {
    title: "config.title",
    desc: "config.description",
    link: "task-manager",
    iconLink: "/images/favicon_prod.svg",
    id: "config",
  },
];

var fqcn_bui = { Bundle: "homeBundle", Unit: "landing", Interface: "landing" };

export default function Landing({
  params: { locale },
}: {
  params: { locale: string };
}) {
  const t = useTranslations("Landing");

  return (
    <>
      <div className="text-center pt-5">
        <h1
          id={getFQCN(fqcn_bui, "title-landing")}
          className="text-2xl font-bold"
        >
          {t("title")}
        </h1>
        <p id={getFQCN(fqcn_bui, "subtitle-landing")}>{t("subtitle")}</p>
      </div>
      <CardListing arrayNav={arrayNav} />
    </>
  );
}
